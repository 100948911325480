import { Box, Typography, Grid, Divider } from "@mui/material";
import { useEffect, useState, useRef, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import Logo from './../../assets/images/logo-circle.png';

interface PropsType {
    tabOpen: boolean,
    setTabOpen: (state: boolean) => void
}

const listMenu = [
    {
        label: 'เข้าสู่ระบบ',
        to: '/login',
        notLoggedInShow: true,
        needLoggedIn: false
    },
    {
        label: 'สมัครสมาชิก',
        to: '/register',
        notLoggedInShow: true,
        needLoggedIn: false
    },
    {
        label: 'โปรโมชั่น',
        to: '/promotions',
        notLoggedInShow: true,
        needLoggedIn: true
    },
    // {
    //     label: 'ทางเข้าเล่น',
    //     to: '/member',
    //     notLoggedInShow: false,
    //     needLoggedIn: true
    // },
    {
        label: 'ฝากเงิน',
        to: '/deposit',
        notLoggedInShow: false,
        needLoggedIn: true
    },
    {
        label: 'ถอนเงิน',
        to: '/withdraw',
        notLoggedInShow: false,
        needLoggedIn: true
    },
    {
        label: 'แนะนำเพื่อน',
        to: '/affiliate',
        notLoggedInShow: false,
        needLoggedIn: true
    },
    {
        label: 'รับยอดเสียของเพื่อน',
        to: '/return-friend-credit',
        notLoggedInShow: false,
        needLoggedIn: true
    },
    {
        label: 'LINE@',
        to: 'https://lin.ee/h3vvUJ2',
        notLoggedInShow: false,
        needLoggedIn: true
    },
    {
        label: 'TELEGRAM',
        to: 'https://t.me/+r9iAShZ-PCMzYTk1',
        notLoggedInShow: false,
        needLoggedIn: true
    },
    {
        label: 'ออกจากระบบ',
        to: '/logout',
        notLoggedInShow: false,
        needLoggedIn: true
    }
]

const MenuForMobile = ({ tabOpen, setTabOpen }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});

    return (
        <>
            <Box sx={
                {
                    display: 'block',
                    maxWidth: {
                        xs: `75%`,
                        sm: `325px`,
                        md: `35%`,
                        lg: `325px`
                    },
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: tabOpen ? 0 : -3000,
                    position: 'fixed',
                    backgroundColor: '#cd1e2f',
                    zIndex: 10000000,
                    color: 'black',
                    transition: 'all .3s',
                    borderLeft: 'solid 3px #000',
                    overflowY: 'hidden'
                }
            }>
                <Box py={2}>
                    <Box width={`100%`} display={`flex`} justifyContent={`center`} alignItems={`center`} alignContent={`center`}>
                        <img src={Logo} alt="" style={{ maxHeight: '150px' }} />
                    </Box>
                    <Box display={`flex`} flexDirection={`column`} mt={1}>
                        {
                            listMenu.filter((element) => {
                                if(!cookies.get('access_token'))
                                {
                                    return element.notLoggedInShow === true
                                }
                                else
                                {
                                    return element.needLoggedIn === true
                                }
                            }).map((item, index) => {
                                return (
                                    <Link to={item.to} key={index} style={{ marginTop: 1.5 }} target={item.label === 'LINE@' || item.label === 'TELEGRAM' ? "_blank" : "_self"}>
                                        <Box py={1} sx={
                                            {
                                                background: 'linear-gradient(to right, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 4%, transparent 84%, transparent 100%)'
                                            }
                                        }>
                                            <Typography px={2} fontSize={16} color={`white`}>
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    </Link>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={
                {
                    display: tabOpen ? 'block' : 'none',
                    maxWidth: `100%`,
                    maxHeight: `100vh`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    backgroundColor: 'rgba(0, 0, 0, .7)',
                    zIndex: 10000000 - 1,
                    transition: 'all .5s'
                }
            } onClick={() => setTabOpen(false)} />
        </>
    )
}

export default MenuForMobile;