import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import { Container, Box, Grid, Typography, Button } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import SavingsIcon from '@mui/icons-material/Savings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AddCardIcon from '@mui/icons-material/AddCard';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { LuCoins } from "react-icons/lu";
import { RiHome3Fill } from "react-icons/ri";
import GradeIcon from '@mui/icons-material/Grade';
import LogoutIcon from '@mui/icons-material/Logout';
import FeedIcon from '@mui/icons-material/Feed';
import UndoIcon from '@mui/icons-material/Undo';

const NavFooterMobile = () => {
    const cookies = new Cookies(null, {path:'/'});
    const navigate = useNavigate();

    const href = (url: string) => {
        navigate(url);
        window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
        });
    }

    return (
        <Box sx={
            {
                display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'block',
                    lg: 'block'
                },
            }
        }>
            <Box sx={{ mb: 6.8 }}>
            </Box>
            <Box sx={
                {
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 4,
                    width: `100%`,
                }
            }>
                    <Container sx={{ maxWidth: '768px !important', px: 0, pt: .5, backgroundColor: "#ff2c55", borderTop: 'solid 1px black' }}>
                    {
                        !cookies.get('access_token') ? <Grid container py={1} px={2} spacing={2}>
                            <Grid item xs={6}>
                                <Button type="submit" fullWidth sx={
                                    {
                                        backgroundColor: '#ffffff',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#ffbe41',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => {
                                    navigate('/login')
                                }}>
                                    <Typography fontSize={14}>
                                        เข้าสู่ระบบ
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="submit" fullWidth sx={
                                    {
                                        backgroundColor: '#ffffff',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#ffbe41',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => {
                                    navigate('/register')
                                }}>
                                    <Typography fontSize={14}>
                                        สมัครสมาชิก
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid> : <Grid container spacing={1}>
                            <Grid item xs={2.4} display={`flex`} flexDirection={`column`} alignContent={`center`} alignItems={`center`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/game/slot" : "/")}>
                                <Typography align="center">
                                    {
                                        cookies.get('access_token') ? <RiHome3Fill /> : <RiHome3Fill />
                                    }
                                </Typography>
                                <Typography align="center" fontSize={10}>
                                    {
                                        cookies.get('access_token') ? "หน้าแรก" : "หน้าแรก"
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={2.4} display={`flex`} flexDirection={`column`} alignContent={`center`} alignItems={`center`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/deposit" : "/deposit")}>
                                <Typography align="center">
                                    {
                                        cookies.get('access_token') ? <PointOfSaleIcon fontSize="small" /> : <PointOfSaleIcon fontSize="small" />
                                    }
                                </Typography>
                                <Typography align="center" fontSize={10}>
                                    {
                                        cookies.get('access_token') ? "ฝาก" : "ฝาก"
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={2.4} display={`flex`} flexDirection={`column`} alignContent={`center`} alignItems={`center`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/promotions" : "/promotions")}>
                                <Typography align="center">
                                    <FeedIcon fontSize="small" />
                                </Typography>
                                <Typography align="center" fontSize={10}>
                                    {
                                        cookies.get('access_token') ? "โปรโมชั่น" : "โปรโมชั่น"
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={2.4} display={`flex`} flexDirection={`column`} alignContent={`center`} alignItems={`center`} sx={{ cursor: 'pointer' }} onClick={() => href(cookies.get('access_token') ? "/return-credit" : "/return-credit")}>
                                <Typography align="center">
                                    {
                                        cookies.get('access_token') ? <UndoIcon /> : <UndoIcon />
                                    }
                                </Typography>
                                <Typography align="center" fontSize={10}>
                                    {
                                        cookies.get('access_token') ? "รับยอดเสียคืน" : "รับยอดเสียคืน"
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={2.4} display={`flex`} flexDirection={`column`} alignContent={`center`} alignItems={`center`} sx={{ cursor: 'pointer' }} onClick={() => href('/logout')}>
                                <Typography align="center">
                                    <LogoutIcon fontSize="small" />
                                </Typography>
                                <Typography align="center" fontSize={10}>
                                    ออกจากระบบ
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Container>
            </Box>
        </Box>
    )
}

export default NavFooterMobile;