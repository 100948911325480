import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";

import { Box, Container, Typography, Button, Grid, FormControl, FormLabel, OutlinedInput, InputAdornment, IconButton, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import { FaLine } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Swal from "sweetalert2";
import toast, { Toaster } from 'react-hot-toast';

const InviteFriend = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [loaded, setLoaded] = useState<boolean>(false);
    const [affiliateUrl, setAffiliateUrl] = useState<string>('');
    const navigate = useNavigate();

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('คัดลอกเรียบร้อยแล้ว');
    }

    useEffect(() => {
        (async () => {
            try {
                if(!cookies.get('access_token'))
                {
                    setLoaded(true);
                    return navigate('/login');;
                }

                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    setLoaded(true);
                    cookies.remove('access_token');

                    return navigate('/login');
                }
                else if(checkToken.status_code === 200)
                {
                    const getAffiliateUrl = await service.getMemberAffiliateUrl(access_token);
                    if(getAffiliateUrl.data.status_code === 200)
                    {
                        setAffiliateUrl(getAffiliateUrl.data.url);
                        setLoaded(true);
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ'
                        })
                    }
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    return (
        loaded ? <Container sx={
                {
                    maxWidth: '600px !important',
                    padding: '0 !important'
                }
            }>
                <Toaster />
                <Box sx={
                    {
                        backgroundColor: 'rgba(0, 0, 0, .15)',
                        minHeight: `100dvh`,
                        height: '100%',
                        mb: 0,
                        pb: 0
                    }
                }>
                    <Box sx={
                        {
                            backgroundColor: '#cd1e2f',
                            px: 2,
                            py: 2.5,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }>
                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`/login`} style={{ color: 'white' }}>
                                <Typography display={`flex`} justifyContent={`center`} alignContent={`center`}>
                                    <KeyboardArrowLeftIcon />กลับไปเข้าสู่ระบบ
                                </Typography>
                            </Link>
                        </Box>
                        <Box></Box>
                        <Box display={`flex`} justifyContent={`flex-end`} alignContent={`center`} alignItems={`center`}>
                            <Link to={`https://lin.ee/h3vvUJ2`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaLine color="#04c854" />&nbsp;LINE@
                                </Button>
                            </Link>
                            <Link to={`https://t.me/+r9iAShZ-PCMzYTk1`} target="_blank">
                                <Button sx={
                                    {
                                        mx: .5,
                                        borderRadius: 15,
                                        px: 2,
                                        backgroundColor: 'rgba(0, 0, 0, .3)',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, .4)'
                                        }
                                    }
                                } size="small">
                                    <FaTelegram color="#34aae4" />&nbsp;TELEGRAM
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={
                        {
                            display: `flex`,
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            height: '90dvh'
                        }
                    }>
                        <Box px={4} py={2}>
                            <Typography fontSize={20} align="center">
                                แนะนำเพื่อนรับ 5% จากยอดเสียของเพื่อน
                            </Typography>
                            <Grid container spacing={2} sx={{ mt: .5 }}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor="user_affiliate">
                                            <Typography color={`white`} fontSize={13}>
                                                ลิงก์ของคุณ
                                            </Typography>
                                        </FormLabel>
                                        <OutlinedInput
                                            name='user_affiliate'
                                            type={`tel`}
                                            size='small'
                                            fullWidth
                                            autoComplete="user_affiliate"
                                            sx={
                                                {
                                                    backgroundColor: 'white'
                                                }
                                            }
                                            value={affiliateUrl.replace('{appurl}', process.env.REACT_APP_BASE_URL || "")}
                                            readOnly
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Typography fontSize={14}>
                                    หมายเหตุ
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography fontSize={14}>
                                            สามารถรับยอดเสียของเพื่อนคืนของแต่ละสัปดาห์ได้แค่วันจันทร์เท่านั้น
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            สามารถรับยอดเสียของเพื่อนคืนได้เพียง 5% เท่านั้น
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            ยอดเสียที่นำมาคำนวณ คือ ยอดเสียของเพื่อนที่ถูกเก็บไว้เป็นวันต่อวัน
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            ยอดเสียที่นำมาคำนวณ คือ ยอดเสียของเพื่อนจากสัปดาห์ที่แล้ว
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            ไม่สามารถรับยอดเสียของเพื่อนคืนย้อนหลังได้
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            ยอดเสียของเพื่อนที่จะได้รับคืนจะถูกตัดทศนิยมออก
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid>
                        </Box>
                        <Box px={2}>
                            <Grid container>
                                <Grid item xs={12} mb={2}>
                                    <Button type="button" fullWidth sx={
                                        {
                                            py: 2,
                                            backgroundColor: '#d64657',
                                            color: 'black',
                                            '&:hover': {
                                                backgroundColor: '#cd1e2f',
                                                color: 'black',
                                            }
                                        }
                                    } onClick={() => {
                                        copyToClipboard(`${affiliateUrl.replace('{appurl}', process.env.REACT_APP_BASE_URL || "")}`)
                                    }}>
                                        <Typography fontSize={16} fontWeight={600}>
                                            คัดลอกลิงก์
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Container>
        : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
            <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                <CircularProgress size={30} sx={
                    {
                        color: '#d64657'
                    }
                } />
            </Box>
        </Box>
    )
}

export default InviteFriend;